import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

import allReducers from "./redux/reducers";

const globalStore = createStore(
    allReducers,
    composeWithDevTools(applyMiddleware(thunk))
);

export default globalStore;
