const defaultState = {
    domains: [],
}

export const DomainListReducer = (state = defaultState, action) => {
    console.log("in reducer")
    console.log(action.payload)

    switch (action.type) {
        case 'DOMAIN_LIST':
            const newState = {
                domains: action.payload
            }

            return newState;
        default:
            return state;
    }
}
