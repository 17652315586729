import React from "react";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/aurora-login-icon.png"
import {useSelector} from "react-redux";

const Navigation = () => {
    const username = useSelector(state => state.login.username);

    return (

        <Navbar expand="lg" variant="light" bg="light">
            <Navbar.Brand href="#">
                <img
                    src={logo}
                    height="30"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                    Signed in as: <a href="#login">{username}</a>
                </Navbar.Text>
            </Navbar.Collapse>
        </Navbar>

    )
}

export default Navigation;
