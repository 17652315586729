import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Switch} from 'react-router-dom';

import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';

function App() {
    return (
        <main>
            <Switch>
                <Route path="/" component={LoginPage} exact />
                <Route path="/dashboard" component={DashboardPage} />
            </Switch>
        </main>
    );
}

export default App;
