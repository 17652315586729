import {LoginFormReducer} from './LoginForm'
import {combineReducers} from "redux";
import {DomainListReducer} from "./DomainList";

const allReducers = combineReducers({
    login: LoginFormReducer,
    domainList: DomainListReducer
});

export default allReducers;
