import React, {useEffect} from "react";
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from "react-bootstrap/Card";
import {useDispatch} from "react-redux";
import {DomainListCall} from "../redux/actions/DomainList";


function DomainList() {
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("dispatching")
        dispatch(DomainListCall())

    }, [dispatch]);

    return (
        <Card style={{width: '18rem'}}>
            <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of
                    the card's content.
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default DomainList;


