import React from "react";
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Navigation from "../components/Navbar";
import DomainList from "../components/DomainList";

const DashboardPage = () => {
    return (
        <div className="App">
            <Navigation />
            <Container>
                <DomainList />
            </Container>
        </div>
    );
}

export default DashboardPage;
