import React from "react";
import '../App.css';
import LoginForm from "../components/LoginForm";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import logo from "../assets/aurora-login-icon.png"


function LoginPage() {
    return (
        <div className="App">
            <header className="App-header">
            </header>
            <div className={"login-page-wrapper"}>
                <Container>
                    <Image src={logo} alt={"aurora logo"}/>
                    <LoginForm/>
                </Container>
            </div>
        </div>
    );
}

export default LoginPage;
