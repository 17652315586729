const defaultState = {
    LoggedIn: false,
    auth_token: 0
}

export const LoginFormReducer = (state = defaultState, action) => {
    console.log("in reducer")
    console.log(action.payload)

    switch (action.type) {
        case 'LOGIN':
            const newState = {
                LoggedIn: true,
                auth_token: action.payload.auth_token
            }

            return newState;
        default:
            return state;
    }
}
