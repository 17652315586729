import globalStore from "../../../store";

export const getApiConfig = () => {
    const token = globalStore.getState().login.auth_token;

    const config = {
        headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }

    return config;
}
