import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {LoginFormCall} from "../redux/actions/LoginForm";

import "./LoginForm.css";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";

const LoginForm = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const loggedIn = useSelector(state => state.login.LoggedIn);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();

        const payload = {
            username: email,
            password: password
        }

        dispatch(LoginFormCall(payload));
    }

    if (loggedIn === true) {
        return (<Redirect to="/dashboard" />);
    } else {
        return (
            <div className="Login">
                <Form onSubmit={handleSubmit}>
                    <Form.Group size="lg" controlId="email">
                        <Form.Control
                            autoFocus
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button block size="lg" type="submit" disabled={!validateForm()}>
                        Login
                    </Button>
                </Form>
            </div>
        );
    }
}

export default LoginForm;
