import {apiBaseUrl} from "../../config";
import axios from 'axios';
import {getApiConfig} from "./auth/ApiAuth";


export function LoginSuccess(result) {
    console.log(result)

    return {
        type: 'LOGIN',
        payload: result
    };
}

// curl -X POST -d '{"username": "admin","password": "admin"}' -H 'Content-Type: application/json'  http://127.0.0.1:8000/api/auth/token/login/
export const LoginFormCall = (payload) => {

    return (dispatch) => {

        return axios.post(apiBaseUrl + "/api/auth/token/login/", payload, getApiConfig())
            .then(({data}) => {
                dispatch(LoginSuccess(data));
            })
            .catch(error => {
                console.log("Error - suggests can't log in.");
                throw (error);
            });
    }
}
