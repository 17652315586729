import {apiBaseUrl} from "../../config";
import axios from 'axios';
import {getApiConfig} from "./auth/ApiAuth";

export function DomainListSuccess(result) {
    console.log("In domain list reducer")

    return {
        type: 'DOMAIN_LIST',
        payload: result
    };
}

export const DomainListCall = () => {
    return (dispatch) => {
        //curl -X GET http://127.0.0.1:8000/api/v1/domains/ -H 'Authorization: Token e9961fd6624eac413fd9959b4fdd51c467f5ecb5'
        return axios.get(apiBaseUrl + "/api/v1/domains/", getApiConfig())
            .then(({data}) => {
                dispatch(DomainListSuccess(data));
            })
            .catch(error => {
                console.log("Error - suggests can't retrieve user's domains.");
                throw (error);
            });
    }
}
